<template>
  <app-page-layout :title="$t('hr.suggestion.add_suggestion')" icon="tw-hr-cost" @close="leavePage">
    <template #content>
      <validation-observer ref="observer">
        <v-row>
          <v-col cols="12">
            <validation-provider :name="$t('hr.suggestion.category')" rules="required" v-slot="{ errors }">
              <suggestion-category-picker v-model="suggestion.category" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('hr.suggestion.body')" rules="required|max:255" v-slot="{ errors }">
              <v-textarea
                v-model="suggestion.body"
                color="tertiary"
                rows="2"
                counter="255"
                :label="$t('hr.suggestion.body')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </validation-observer>
    </template>
    <template #footer>
      <app-page-footer create>
        <template #right>
          <v-btn text @click="validateAndSave" :loading="loading">{{ $t("buttons.save") }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  import Suggestion from "../model/Suggestion";

  export default {
    name: "SuggestionCreate",
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer"),
      SuggestionCategoryPicker: () => import("../components/SuggestionCategoryPicker")
    },
    data: () => ({
      loading: false,
      suggestion: new Suggestion()
    }),
    methods: {
      leavePage() {
        this.$router.replace({ name: "suggestions" });
      },
      validateAndSave() {
        this.$refs.observer.validate().then(valid => {
          if (valid) {
            this.loading = true;
            this.$api.suggestionService
              .save(this.suggestion)
              .then(({ data }) => {
                if (!data.error) {
                  this.$emit("success:save");
                  this.leavePage();
                }
              })
              .catch(e => {
                this.$helpers.showNotification(e.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      }
    }
  };
</script>

<style scoped></style>
